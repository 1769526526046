import React from 'react'
import styled from "styled-components"

import gold from "../../../../assets/images/gold.svg"
import bronze from "../../../../assets/images/bronze.svg"
import silver from "../../../../assets/images/silver.svg"
import pencil from "../../../../assets/images/pencil.svg"
import notebook from "../../../../assets/images/notebook.svg"
import illustration from "../../../../assets/images/illustration.png"
import edit from "../../../../assets/images/edit.png"
import add from "../../../../assets/images/add.png"
import { Button } from "../../../reusableComponents/Button"
import { Divider } from "../../../reusableComponents/Divider"

function Packages() {
  return (
    <Container>
      <Section isGrid={true} gap={"3rem"}>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true} gap={"0"}>
              <Wrapper>
                <ImageContainer>
                  <ImageSvg src={bronze}/>
                </ImageContainer>
                <Tag>Bronze<br /> Package</Tag>
              </Wrapper>
              <Price>$999</Price>
            </Wrapper>
            <Description>Our Bronze package lets you begin your publishing journey with all the essential publishing, marketing, and distribution services.</Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true} gap={"0"}>
              <Wrapper>
                <ImageContainer>
                  <ImageSvg src={silver}/>
                </ImageContainer>
                <Tag>Silver<br /> Package</Tag>
              </Wrapper>
              <Price>$1,599</Price>
            </Wrapper>
            <Description>Our Silver package has a wide array of publishing tools that can help you reach more readers.</Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
        <ItemWrapper>
          <Wrapper isColumn={true}>
            <Wrapper isColumn={true}>
              <Wrapper>
                <ImageContainer height={"80px"}>
                  <ImageSvg src={gold}/>
                </ImageContainer>
                <Tag>Gold<br /> Package</Tag>
              </Wrapper>
              <Price>$5,999</Price>
            </Wrapper>
            <Description>Our Gold package has everything you need to go beyond and achieve your literary goals.</Description>
          </Wrapper>
          <Button round="true" to="/contact">
            Read More
          </Button>
        </ItemWrapper>
      </Section>
      <Divider></Divider>
      <Section gap={"3rem"}>
        <Wrapper isColumn={true}>
          <SecondaryHeader>Children's Book Publishing</SecondaryHeader>
        </Wrapper>
        <Section isGrid={true} gap={"3rem"}>
          <ItemWrapper>
            <Wrapper isColumn={true}>
              <Wrapper isColumn={true}>
                <Wrapper>
                  <ImageContainer height={"80px"}>
                    <ImageSvg src={pencil}/>
                  </ImageContainer>
                  <Tag>Standard<br /> Package</Tag>
                </Wrapper>
                <Price>$1,499</Price>
              </Wrapper>
              <Description>Our Standard package lets you begin your publishing journey with all the essential publishing, marketing, and distribution services.</Description>
            </Wrapper>
            <Button round="true" to="/contact">
              Read More
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <Wrapper isColumn={true}>
              <Wrapper isColumn={true}>
                <Wrapper>
                  <ImageContainer height={"80px"}>
                    <ImageSvg src={notebook}/>
                  </ImageContainer>
                  <Tag>Premium<br /> Package</Tag>
                </Wrapper>
                <Price>$2,999</Price>
              </Wrapper>
              <Description>Our Premium package has a wide array of publishing tools that can help you reach more readers.</Description>
            </Wrapper>
            <Button round="true" to="/contact">
              Read More
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <Wrapper isColumn={true}>
              <Wrapper isColumn={true}>
                <Wrapper>
                  <ImageContainer height={"80px"}>
                    <ImageSvg src={gold}/>
                  </ImageContainer>
                  <Tag>Deluxe<br /> Package</Tag>
                </Wrapper>
                <Price>$4,999</Price>
              </Wrapper>
              <Description>Our Deluxe package has everything you need to go beyond and achieve your literary goals.</Description>
            </Wrapper>
            <Button round="true" to="/contact">
              Read More
            </Button>
          </ItemWrapper>
        </Section>
      </Section>
      <Divider></Divider>
      <Section gap={"3rem"}>
        <Wrapper isColumn={true}>
          <SecondaryHeader>Other Publishing Services</SecondaryHeader>
        </Wrapper>
        <Section isGrid={true} gap={"3rem"}>
          <ItemWrapper>
            <Wrapper isColumn={true}>
              <Wrapper isColumn={true}>
                <Wrapper>
                  <ImageContainer height={"80px"}>
                    <ImageSvg src={edit}/>
                  </ImageContainer>
                  <Tag>Editorial<br /> Services</Tag>
                </Wrapper>
              </Wrapper>
              <Description>Creating a professional manuscript can take months, or even years. Whether you are a first-time author or an experienced writer, Writers way solutions professional in-house specialists can help you improve and enhance the readability and quality of your book.</Description>
            </Wrapper>
            <Button round="true" to="/contact">
              Read More
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <Wrapper isColumn={true}>
              <Wrapper isColumn={true}>
                <Wrapper>
                  <ImageContainer height={"80px"}>
                    <ImageSvg src={illustration}/>
                  </ImageContainer>
                  <Tag>Illustration<br /> Services</Tag>
                </Wrapper>
              </Wrapper>
              <Description>Bring your stories to life with unique and captivating illustrations crafted by our talented artists. From intricate character designs to enchanting landscapes, we'll tailor each illustration to perfectly complement your narrative.</Description>
            </Wrapper>
            <Button round="true" to="/contact">
              Read More
            </Button>
          </ItemWrapper>
          <ItemWrapper>
            <Wrapper isColumn={true}>
              <Wrapper isColumn={true}>
                <Wrapper>
                  <ImageContainer height={"80px"}>
                    <ImageSvg src={add}/>
                  </ImageContainer>
                  <Tag>Add-on<br /> Services</Tag>
                </Wrapper>
              </Wrapper>
              <Description>Formatting and design are key elements to the readability and overall impact of your book. At Writers way solutions, you can customize your publishing package with a wide variety of additional services designed to complement your supported self-publishing experience.</Description>
            </Wrapper>
            <Button round="true" to="/contact">
              Read More
            </Button>
          </ItemWrapper>
        </Section>
      </Section>
    </Container>
  )
}

export default Packages


const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 4rem calc((100vw - 1200px) / 2);
  padding-top: 1rem;
`

const Section = styled.section`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  justify-content: space-between;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ gap }) => (gap ? gap : "1rem")};
  align-items: ${({ isColumn }) => (isColumn ? "flex-start" : "center")};
  width: 100%;
`

const Tag = styled.h1`
  color: ${({ color }) => (color ? color : "#333")};
  font-size: 2rem;
`

const Description = styled.p`
  color: ${props => props.theme.colors.dark4};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
`
const Price = styled.h6`
  color: ${props => props.theme.colors.accent6};
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.5;
  display: none;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: ${({ height }) => (height ? height : "100px")};
  overflow:hidden;
`
const ImageSvg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`

const SecondaryHeader = styled.h4`
  color: ${({ color }) => (color ? color : "#e85d49")};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
`