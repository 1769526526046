import React from 'react'
import styled from "styled-components"

import writer from "../../../../assets/images/modified-writer.svg"

function Hero() {
  return (
    <Container>
      <Section gap={"3rem"}>
        <Wrapper isColumn={true} noGap={true}>
           <TitleSpan>Writers Way</TitleSpan>
           <Title>Publishing Packages</Title>
        </Wrapper>
        <Wrapper isGrid={true}>
           <Description>
             Find the perfect combination of professional services for your book with our help. By publishing with us, you gain the years of experience and industry knowledge that we've built into our packages. No need to track down designers, editors and distributors on your own! Simply find the best fit and follow your passion.
           </Description>
           <ImageContainer>
              <ImageSvg src={writer}/>
           </ImageContainer>
        </Wrapper>
      </Section>

      <Section gap={".5rem"}>
        <SecondaryHeader>Where to Start</SecondaryHeader>
        <Description>
          Our packages give you everything you need to reach your publishing goals, combining the most powerful tools and services to help you create a professional-quality book. We pair our top-notch editing, designing and marketing features with our Editor's Choice program, a unique initiative to help books achieve editorial superiority. 
        </Description>
      </Section>
      <Divider></Divider>
    </Container>
  )
}

export default Hero

const Container = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem calc((100vw - 1200px) / 2);
`

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ gap }) => (gap ? gap : "1rem")};
`

const Wrapper = styled.div`
  display: ${({ isGrid }) => (isGrid ? "grid" : "flex")};
  flex-direction: ${({ isColumn }) => (isColumn ? "column" : "row")};
  gap: ${({ noGap }) => (noGap ? "0" : "1rem")};
  grid-template-columns: 1fr 1fr;
`

const TitleSpan = styled.h1`
  color: ${props => props.theme.colors.dark4};
  font-size: 5.5rem;
  font-weight:300;
  line-height: 1;
`

const Title = styled.h1`
  color: ${props => props.theme.colors.accent7};
  font-size: 5.5rem;
`

const Description = styled.p`
  color: ${props => props.theme.colors.dark4};
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: justify;
`
const SecondaryHeader = styled.h4`
  color: ${props => props.theme.colors.accent5};
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.5;
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
`
const ImageSvg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  overflow: hidden;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.colors.dark3};
  margin-top: 3rem;
`
